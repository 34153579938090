import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Footer from './../Footer';

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.screenSecond = React.createRef();
  }

  moveToSecond = event => {
    event.preventDefault();
    event.stopPropagation();
    this.screenSecond.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="content">
          <header className="header d-flex">
            <video autoPlay="autoplay" loop="loop" muted="muted">
              <source src="/img/video.mp4" type="video/mp4"/>
            </video>
            <div className="header__wrap">
              <h1>Найзірковіший шрифт сторіччя<br/> Тицяй, щоб створити вітальну магiю!</h1>
              <div className="btn-wrap" onClick={this.moveToSecond}>
                <a href="#" className="btn">Тиць!</a>
              </div>
            </div>
          </header>
          <main className="main" ref={this.screenSecond}>
            <div className="img-box d-flex">
              <div className="img-box__item">
                <img src="/img/img-box-1.jpg" alt="img item"/>
              </div>

              <div className="img-box__item">
                <img src="/img/img-box-2.jpg" alt="img item"/>
              </div>

              <div className="img-box__item">
                <img src="/img/img-box-3.jpg" alt="img item"/>
              </div>

              <div className="img-box__item">
                <img src="/img/img-box-4.jpg" alt="img item"/>
              </div>
            </div>

            <section className="section desc-box text-center">
              <h2>Як це працює:</h2>

              <div className="row-wrap d-flex j-content-center">
                <div className="col">
                  <div className="step-number step-number-1 step-number-arrow">1</div>
                  <div className="text">Обери настрій</div>
                </div>

                <div className="col">
                  <div className="step-number step-number-2 step-number-arrow">2</div>
                  <div className="text">Додай ім‘я</div>
                </div>

                <div className="col">
                  <div className="step-number step-number-3">3</div>
                  <div className="text">Роби меджик</div>
                </div>
              </div>
              <div className="btn-wrap">
                <Link to="/create" className="btn">
                  Створити листівку
                </Link>
              </div>
            </section>
          </main>
        </div>
     
        <Footer />  
      </div>
    );
  }
}

export default Home;
