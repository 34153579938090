export default {
  '1': [ 'щастячка', 'та', 'здоровля', '' ],
  '2': [ 'затишного', 'різдва', '' ],
  '3': [ 'святкових', 'пригод', '' ],
  '4': [ 'мільйона', 'лайків', '' ],
  '5': [ 'радісних', 'свят', '' ],
  '6': [ 'їж', 'танцюй', 'святкуй', '' ],
  '7': [ 'з новим', 'роком', '' ],
  '8': [ 'щастячка', 'і', 'грошиків', '' ],
  '9': [ 'мрій', 'без меж', '' ]
};
