import React, { PureComponent } from 'react';
import baseUrl from './../../helpers/baseUrl';

class G8 extends PureComponent
{
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const { rowsCount, content, inputHandle, shareHandle, charImgWidth } = this.props;
    let rows = [];

    for ( let i = 0; i < rowsCount; i++ ) {

      let row = [];

      for ( let j = 0; j < 8; j++ ) {

        let char = ( content[i][j] !== undefined && content[i][j] !== ' ' ) ? content[i][j] : 'пробел';

        row.push((<div className="step-page-second__item" key={j}><img src={`${baseUrl}/chars/${charImgWidth}/${char}.jpg`} /></div>));
      }

      rows.push(row);
    }

    if ( rowsCount === 3 ) {
      let row = [];

      for ( let j = 0; j < 8; j++ )
        row.push((<div className="step-page-second__item" key={j}><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>));

      rows.push(row);
    }

    return (
      <div className="step-page__content col-8">
        <div className="step-page-second__row d-flex">
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
       
          <div className="title-wrap d-flex j-content-center align-items-center mobile-hidden">
            <h2>
              Напиши ім’я когось важливого:
              <input type="text" placeholder="Ввести ім’я" value={content[rowsCount-1]} onChange={inputHandle} ref={this.inputRef} />
            </h2>
          </div> 
        </div>

        <div className="step-page-second__row d-flex">
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>  
        </div>

        {rows.map((el, i) => (
          <div className="step-page-second__row d-flex" key={i}>
            {el.map((el, i) => el)}
          </div>
        ))}

        <div className="step-page-second__row d-flex">
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div> 
        </div>

        <div className="step-page-second__row d-flex">
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div>
          <div className="step-page-second__item"><img src={`${baseUrl}/chars/${charImgWidth}/пробел.jpg`} /></div> 
        </div>

        <a href="#" className="btn" onClick={shareHandle}>Надіслати</a>
      </div>
    );
  }
}

export default G8;
