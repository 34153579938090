import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton, TelegramShareButton, ViberShareButton } from 'react-share';
import postcardsContent from './../../_data/postcardsContent';
import postcardChars from './../../_data/chars';
import baseUrl from './../../helpers/baseUrl';
import { withRouter } from 'react-router-dom';
import Footer from './../Footer';
import * as Grids from './../Grid';

const calculatePostcardInfo = content => {
  const rowsCount = content.length;
  let charsMaxCount = 0;

  for ( let i = 0; i < rowsCount; i++ )
    if ( charsMaxCount < content[i].length )
      charsMaxCount = content[i].length;

  return {
    charsMaxCount,
    rowsCount,
    content
  };
};

class Create extends Component
{
  constructor(props) {
    super(props);
    const id = props.match.params.id;
    const windowWidth = window.window.innerWidth;
    const content = ( postcardsContent[id] !== undefined ) ? postcardsContent[id] : postcardsContent[1];
    const postcardInfo = calculatePostcardInfo(content);
    const charImgWidth = ( windowWidth < 1450 ) ? 208 : ( windowWidth < 1620 ) ? 240 : ( windowWidth < 1950 ) ? 320 : 480;
    this.state = { ...postcardInfo, share: false, image: null, charImgWidth: charImgWidth };
    window.scrollTo(0, 0)
  }

  inputHandle = event => {
    let value = event.target.value;
    let content = [ ...this.state.content ];
    let length = value.length;

    if ( length > 9 || ( length !== 0 && ! postcardChars.includes(value[length - 1]) ) ) return false;

    content[content.length - 1] = value;
    this.setState(calculatePostcardInfo(content));
  }

  shareClose = () => {
    this.setState({ share: false, image: null });
  }

  imageUrl = () => {
    return baseUrl + '/uploads/'+ this.state.image;
  }

  openImage = img => event => {
    event.preventDefault();
    event.stopPropagation();

    const url = baseUrl + '/uploads/'+ img;
    const win = window.open(url, '_blank');

    win.focus();
  }

  submitHandle = event => {
    event.preventDefault();
    event.stopPropagation();

    fetch('/index.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content: this.state.content }),
      })
      .then(response => response.json())
      .then(data => this.setState({ share: true, image: data.image }))
      .catch(error => console.log('FETCH_ERROR', error));
  }

  render() {
    const { image, share, content, rowsCount, charsMaxCount, charImgWidth } = this.state;
    const Grid = Grids['G'+ charsMaxCount];

    const Share = share === false ? null : (
      <Fragment>
        <div className="overlay active" onClick={this.shareClose}></div>

        <div className="modal step3 text-center">
          <div className="title">
            Подiлитись
          </div>

          <div className="social-box text-center">
            <div className="social-box__wrap"> 
              <FacebookShareButton url={this.imageUrl()}>
                <a href="#" className="social-box__item"> 
                  <img src="/img/svg/facebook-logo.svg" alt="facebook logo"/>
                </a>
                <div className="social-box__name">facebook</div>
              </FacebookShareButton>
            </div>
            <div className="social-box__wrap">
              <TelegramShareButton url={this.imageUrl()}>
                <a href="#" className="social-box__item"> 
                  <img src="/img/svg/telegram-logo.svg" alt="telegram logo"/>
                </a>
                <div className="social-box__name">telegram</div>
              </TelegramShareButton>
            </div>
            <div className="social-box__wrap">
              <ViberShareButton url={this.imageUrl()}>
                <a href="#" className="social-box__item social-box__item-viber"> 
                  <img src="/img/svg/viber-logo.svg" alt="viber logo"/>
                </a>
                <div className="social-box__name">viber</div>
              </ViberShareButton>
            </div>
            <div className="social-box__wrap">
              <a href="#" className="social-box__item" download onClick={this.openImage(image)}> 
                <img src="/img/svg/download-icon.svg" alt="download logo"/>
              </a>
              <div className="social-box__name">завантажити</div>
            </div>
            <div className="social-box__wrap"> 
              <CopyToClipboard text={this.imageUrl()}>
                  <a href="#" className="social-box__item"> 
                    <img src="/img/svg/copy.svg" alt="copy logo"/>
                  </a>
              </CopyToClipboard>
              <div className="social-box__name">
                коп.<br/>посилання
              </div>
            </div>
          </div>

          <CopyToClipboard text={this.imageUrl()}>
            <a href="#" className="btn" onClick={event => event.preventDefault()}>
              <span className="btn__url">https//images..</span>
                Скопіювати
            </a>
          </CopyToClipboard>
        </div>
      </Fragment>
    );

    return (
      <div className="wrapper">
        <div className="content">
          <main className="main">
            <section className="section step-page step-page-second text-center">
              <div className="input-mobile">
                <input type="text" placeholder="Напиши ім’я когось важливого" value={content[rowsCount-1]} onChange={this.inputHandle}/>
              </div>
              <div className="row-wrap d-flex j-content-center mobile-hidden">
                <div className="col" onClick={() => this.props.history.push('/create')}>
                  <div className="step-number step-number-1 step-number-active">1</div>
                  <div className="text">Обери настрій</div>
                </div>

                <div className={share ? 'col' : 'col active'}>
                  <div className="step-number step-number-2 step-number-arrow">2</div>
                  <div className="text">Додай ім‘я</div>
                </div>

                <div className={share ? 'col active' : 'col'}>
                  <div className="step-number step-number-3 step-number-arrow">3</div>
                  <div className="text">Роби меджик</div>
                </div>
              </div>

              <Grid content={content} rowsCount={rowsCount} charsMaxCount={charsMaxCount} inputHandle={this.inputHandle}
                shareHandle={this.submitHandle} charImgWidth={charImgWidth}
              />
              {Share}
              <a href="#" className="btn btn-mobile" onClick={this.submitHandle}>Надіслати</a>
            </section>
          </main>
        </div>

        <Footer />  
      </div>
    );
  }
}

export default withRouter(Create);
