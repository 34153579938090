export default [
  { original: '/img/postcard-tmpl-1.jpg' },
  { original: '/img/postcard-tmpl-2.jpg' },
  { original: '/img/postcard-tmpl-3.jpg' },
  { original: '/img/postcard-tmpl-4.jpg' },
  { original: '/img/postcard-tmpl-5.jpg' },
  { original: '/img/postcard-tmpl-6.jpg' },
  { original: '/img/postcard-tmpl-7.jpg' },
  { original: '/img/postcard-tmpl-8.jpg' },
  { original: '/img/postcard-tmpl-9.jpg' }
];
