import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PostcardTypes from './../components/Postcard/Types';
import PostcardCreate from './../components/Postcard/Create';
import NoMatch from './../components/NoMatch';
import Home from './../components/Home';

export default () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/create" component={PostcardTypes} />
    <Route exact path="/create/:id" component={PostcardCreate} />
    <Route path="*" component={NoMatch} />
  </Switch>
);
