import React from 'react';

export default () => (
  <footer className="footer">
    <div className="footer-top text-center">
      <a href="https://tet.tv" target="_blanc">
        <img src="/img/tet-img.png" alt="tet icon" className="tet-img"/>
      </a>
    </div>

    <div className="social-box text-center">
      <a href="https://www.facebook.com/tet.tv" className="social-box__item" target="_blanc">
        <img src="/img/svg/facebook-logo.svg" alt="facebook logo"/>
      </a>

      <a href="https://t.me/kinchik_pid_vinchik" className="social-box__item" target="_blanc">
        <img src="/img/svg/telegram-logo.svg" alt="telegram logo"/>
      </a>

      <a href="https://instagram.com/tet_tv" className="social-box__item" target="_blanc">
        <img src="/img/svg/instagram-logo.svg" alt="instagram logo"/>
      </a>

      <a href="https://www.youtube.com/user/ChannelTET1" className="social-box__item" target="_blanc">
        <img src="/img/svg/youtube-logo.svg" alt="youtube logo"/>
      </a>
    </div>
  </footer> 
);
