import React, { PureComponent } from 'react';
import ImageGallery from 'react-image-gallery';
import postcardsImages from './../../_data/postcardsImages';
import { Link } from 'react-router-dom';
import Footer from './../Footer';

export default class Create extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = { imageSlide: 0 };
    window.scrollTo(0, 0)
  }

  imgSlide = index => {
    this.setState({ imageSlide: ++index });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="content">
          <main className="main">
            <section className="section step-page step-page-one text-center">
              <div className="row-wrap d-flex j-content-center mobile-hidden">
                <div className="col">
                  <div className="step-number step-number-1">1</div>
                  <div className="text">Обери настрій</div>
                </div>

                <div className="col">
                  <div className="step-number step-number-2 step-number-arrow">2</div>
                  <div className="text">Додай ім‘я</div>
                </div>

                <div className="col">
                  <div className="step-number step-number-3 step-number-arrow">3</div>
                  <div className="text">Роби меджик</div>
                </div>
              </div>

              <h2>Обери дизайн листівки</h2>

              <div className="container-wrap">
                <div className="step-page__content d-flex f-wrap">
                  <div className="img-item">
                    <img src="/img/postcard-tmpl-1.jpg" alt="Postcard item"/>
                    <Link to="/create/1" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-2.jpg" alt="Postcard item"/>
                    <Link to="/create/2" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-3.jpg" alt="Postcard item"/>
                    <Link to="/create/3" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-4.jpg" alt="Postcard item"/>
                    <Link to="/create/4" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-5.jpg" alt="Postcard item"/>
                    <Link to="/create/5" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-6.jpg" alt="Postcard item"/>
                    <Link to="/create/6" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-7.jpg" alt="Postcard item"/>
                    <Link to="/create/7" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-8.jpg" alt="Postcard item"/>
                    <Link to="/create/8" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>

                  <div className="img-item">
                    <img src="/img/postcard-tmpl-9.jpg" alt="Postcard item"/>
                    <Link to="/create/9" className="btn btn__pink">
                      Створити листівку
                    </Link>
                  </div>
                </div>
                <ImageGallery
                  items={postcardsImages} showFullscreenButton={false} showPlayButton={false}
                  showThumbnails={false} onSlide={this.imgSlide}
                />
                <Link to={`/create/${this.state.imageSlide}`} className="btn btn-mobile">Обрати</Link>
              </div>
            </section>
          </main>
        </div>
     
        <Footer />  
      </div>
    );
  }
}
